
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import ResourceManger from "../ResourceManger.vue";
import ComponentItemFormMixin from "./item-form.mixin";

@Component({
  components: {},
  mixins: [],
})
export default class ImgFormBuilder extends Mixins(ComponentItemFormMixin) {
  private showDialog = false;

  render() {
    const { value } = this;
    return (
      <div>
        <q-dialog v-model={this.showDialog}>
          <q-card
            style="width: 90vw; max-width: 1500px; height: 90vh"
            class="bg-white"
          >
            <div class="fit column no-wrap">
              <header class="row justify-between items-center no-wrap q-pa-sm bg-grey-3">
                <span class="text-h5 text-blod text-grey-10 q-pl-md">
                  图片资源库
                </span>
                <q-btn flat v-close-popup dense class="q-px-xs q-py-xs">
                  <q-icon size="18px" name="fas fa-times" />
                </q-btn>
              </header>
              <main style=" height: 0;" class="col-grow">
                <ResourceManger
                  selectMode={true}
                  onSelect={(obj: any) => this.$emit("change", obj.url)}
                />
              </main>
            </div>
          </q-card>
        </q-dialog>

        {value ? (
          <div class="column items-center">
            <div class="bg-grey-2 q-pa-md rounded-borders shadow-1">
              <img
                src={value}
                style="max-width: 120px;max-height: 120px; display: block; margin: auto"
              ></img>
            </div>
            <q-btn
              flat
              onClick={() => (this.showDialog = true)}
              class="q-ma-sm bg-primary text-white rounded-borders"
            >
              <q-icon name="fas fa-image" class="q-pr-sm" size="1rem" />
              重新选择图片
            </q-btn>
          </div>
        ) : (
          <div class="column items-center">
            <q-btn
              flat
              onClick={() => (this.showDialog = true)}
              class="q-ma-sm bg-primary text-white rounded-borders"
            >
              <q-icon name="fas fa-image" class="q-pr-sm" size="1rem" />
              选择图片
            </q-btn>
          </div>
        )}
      </div>
    );
  }
}
